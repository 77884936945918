import { useState } from "react";
import toast from "react-hot-toast";
import { analytics } from "services/analytics.service";
import { ApiService } from "services/api.service";
import { HUBSPOT_NEWSLETTER_FORM } from "utils/constants";
import Button from "../library/Button";
import { LoadingIcon } from "../library/Icons";
import { classNames } from "../library/utils/classNames";

export const NewsletterForm = ({ shade }: { shade?: "blue" | "black" }) => {
  const buttonText = "Subscribe";
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    email: "",
  });
  const [signedUp, setSignedUp] = useState(false);

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent,
  ) => {
    e.preventDefault();
    setLoading(true);
    analytics.identify(undefined, { email: form.email });
    analytics.track("Form Submitted", {
      form: "newsletter-signup",
      email: form.email,
    });
    try {
      const result = await ApiService.post(
        "/api/hubspot-form",
        { ...form, formGuid: HUBSPOT_NEWSLETTER_FORM },
        {},
        (e, method, url, data, config) => {
          const message = e?.response?.data?.message;
          console.log("Failed to fetch:", e, method, url, data, config);
          if (message) {
            toast.error(message);
          }
        },
      );
      if (result.status === 200) {
        setSignedUp(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return signedUp ? (
    <div className="rounded border !border-gray-200">
      <div className="flex h-[32px] items-center  py-8 pl-12 text-13-20 text-gray-900">
        Successfully subscribed!
      </div>
    </div>
  ) : (
    <form className="flex w-full flex-row gap-8" onSubmit={handleSubmit}>
      <div className="flex w-full justify-center gap-12 rounded border !border-gray-200">
        <input
          type="email"
          name="email"
          id="email"
          required
          className="form-input block h-[34px] w-full  rounded border-0 text-13-20 text-gray-900 ring-0 placeholder:text-gray-400 focus:ring-0 "
          placeholder="Your email"
          value={form.email}
          onChange={(e) => {
            setForm({
              ...form,
              email: e.target.value,
            });
          }}
        />
      </div>
      <Button
        type="submit"
        shade={"gray"}
        className={classNames(
          loading ? "pl-20 pr-16" : "px-32",
          "rounded !shadow-button-1",
          "my-1 !border-0 !px-[12px] !py-[6px] hover:!border hover:border-gray-200 hover:bg-gray-100 hover:!px-[11px] hover:!py-[5px]",
        )}
        square
        loading={loading}
        IconRight={loading ? LoadingIcon : undefined}
        size={32}
      >
        {buttonText}
      </Button>
    </form>
  );
};
