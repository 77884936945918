import { ArrowUpRightIcon } from "@components/library/Icons";
import { classNames } from "@components/library/utils/classNames";
import Link from "next/link";

interface FooterLink {
  title: string;
  url: string;
  display?: JSX.Element;
}

interface MarketingCardProps {
  image: string;
  imageAlt: string;
  imageProps?: string;
  header: string;
  description?: string;
  href: string;
}

const NewsCard = ({
  image,
  imageAlt,
  imageProps,
  header,
  description,
  href,
}: MarketingCardProps) => {
  return (
    <div className="flex w-full flex-row gap-16 md:gap-24">
      <Link href={href} target="_blank" className="shrink-0">
        <img
          src={image}
          alt={imageAlt}
          className={classNames("rounded", imageProps)}
        />
      </Link>

      <div className="flex flex-col items-start justify-between gap-2 md:justify-start md:gap-8">
        <a href={href} target="_blank" className="shrink-0">
          <div className="group inline-flex items-center gap-4 text-14-20 text-gray-900 hover:underline dark:text-white md:text-14-24">
            {!description ? (
              <p>
                {header}
                <span className="inline-flex items-baseline">
                  <ArrowUpRightIcon className="h-16 w-16  shrink-0 translate-y-1 self-center pl-4 pt-4 text-gray-300 group-hover:text-gray-600" />
                </span>
              </p>
            ) : (
              <p>{header}</p>
            )}
          </div>
        </a>
        {description && (
          <a href={href} target="_blank" rel="noreferrer" className="shrink-0">
            <p className="inline-flex items-center gap-4 text-14-20 text-gray-600 hover:underline md:text-12-16">
              {description}
              <span className="inline-flex items-baseline">
                <ArrowUpRightIcon className="h-16 w-16 shrink-0 translate-y-1 self-center pl-4 pt-4 text-gray-300" />
              </span>
            </p>
          </a>
        )}
      </div>
    </div>
  );
};

export const FooterLatestNews = () => {
  return (
    <div className="mx-auto flex flex-col justify-between md:flex-row">
      <div className="flex flex-col pb-24 md:w-1/2 md:pb-0">
        <NewsCard
          imageProps="md:h-[160px] md:w-[256px] h-[80px] w-[120px]"
          image="/blog/fine-tuning-gpt-3-5/fine-tuning-3-5.png"
          imageAlt="Fine-tuning gpt-3.5"
          header="Fine-tuning GPT-3.5-Turbo"
          href={"/blog/fine-tuning-gpt-3-5"}
        />
      </div>
      <div className="flex flex-col justify-between gap-[24px] md:w-2/5">
        <NewsCard
          imageProps="md:h-[60px] md:w-[96px] h-[80px] w-[120px]"
          image="/blog/prompt-engineering-101/cover2.png"
          imageAlt="Prompt Engineering 101"
          header="Prompt Engineering 101"
          href={"/blog/prompt-engineering-101"}
        />
        <NewsCard
          imageProps="md:h-[60px] md:w-[96px] h-[80px] w-[120px]"
          image="/blog/tools/tools-social@2x.png"
          imageAlt="Announcement"
          header="Humanloop Tools: Connecting LLMs to resources"
          href={"/blog/announcing-tools"}
        />
      </div>
    </div>
  );
};
