import {
  TitleAndMetaTags,
  TitleAndMetaTagsProps,
} from "components/TitleAndMetaTags";
import { useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import Rulers from "./Rulers";

type LayoutProps = {
  banner?: React.ReactNode;
  xlWidth?: boolean;
  noHeader?: boolean;
  showLatestNews?: boolean;
} & (TitleAndMetaTagsProps | { noTags: true });

const Layout = (props: React.PropsWithChildren<LayoutProps>) => {
  const [bannerOpen, setBannerOpen] = useState(true);
  return (
    <div>
      {/* <Rulers /> */}
      {"noTags" in props ? null : <TitleAndMetaTags {...props} />}
      {/* <Banner open={bannerOpen} setOpen={setBannerOpen} /> */}
      {/* <VideoBanner /> */}

      {!props.noHeader && <Header xlWidth={props.xlWidth || false} />}
      {props.children}
      <Footer showLatestNews={props?.showLatestNews} />
    </div>
  );
};

export default Layout;
