import { classNames } from "./utils/classNames";
import styles from "./Badge.module.css";

export const enum BadgeShade {
  Gray = "gray",
  LightGray = "light-gray",
  Blue = "blue",
  LightBlue = "light-blue",
  Cyan = "cyan",
  Purple = "purple",
  Navy = "navy",
  Orange = "orange",
  Fuchsia = "fuchsia",
  Yellow = "yellow",
  Green = "green",
  Red = "red",
}

export type BadgeSize = 16 | 20 | 24;

export interface BadgeProps {
  className?: string;
  size?: BadgeSize;
  shade?: BadgeShade;
  children?: React.ReactNode;
}

const Badge = ({
  className,
  size = 16,
  shade = BadgeShade.Gray,
  children,
}: BadgeProps) => {
  const classes = {
    base: "flex justify-center items-center rounded-full px-[5px] py-1 text-center font-bold",
    size: {
      16: "min-w-[16px] h-16 text-11-16",
      20: "min-w-[20px] h-20 text-12-14",
      24: "min-w-[24px] h-24 text-14-16",
    },
    shade: {
      [BadgeShade.Gray]: "bg-gray-200 text-gray-900",
      [BadgeShade.LightGray]: "bg-gray-300 text-gray-800",
      [BadgeShade.Blue]: "bg-blue-700 text-white ",
      [BadgeShade.LightBlue]: "bg-blue-200 text-blue-700",
      [BadgeShade.Cyan]: "bg-cyan-300 text-cyan-800",
      [BadgeShade.Purple]: "bg-purple-300 text-purple-800",
      [BadgeShade.Navy]: "bg-navy-300 text-navy-800",
      [BadgeShade.Orange]: "bg-orange-300 text-orange-800",
      [BadgeShade.Fuchsia]: "bg-fuchsia-300 text-fuchsia-800",
      [BadgeShade.Yellow]: "bg-yellow-300 text-yellow-800",
      [BadgeShade.Green]: "bg-green-300 text-green-800",
      [BadgeShade.Red]: "bg-red-300 text-red-800",
    },
  };

  return (
    <div
      className={classNames(
        styles.badge,
        classes.base,
        classes.size[size],
        classes.shade[shade],
        className,
      )}
    >
      {children}
    </div>
  );
};

export default Badge;
