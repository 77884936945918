import { ArrowUpRightIcon } from "@components/library/Icons";
import Badge from "components/library/Badge";
import { HumanloopLogo } from "components/library/Logos";
import Link from "next/link";
import {
  ABOUT_PAGE_LINK,
  CAREERS_LINK,
  GITHUB_LINK,
  DOCS_LINK,
  LINKEDIN_LINK,
  NUMBER_OF_JOB_OPENINGS,
  TWITTER_LINK,
  VANTA_TRUST_REPORT_LINK,
} from "utils/constants";
import { FooterLatestNews } from "../home/FooterLatestNews";
import { NewsletterForm } from "@components/home/NewsletterForm";

interface FooterLink {
  title: string;
  url: string;
  display?: JSX.Element;
  target?: "_blank";
}

export const FooterLinks: Record<string, FooterLink[]> = {
  Resources: [
    {
      title: "Blog",
      url: "/blog",
    },
    {
      title: "Docs",
      url: DOCS_LINK,
      target: "_blank",
    },
    {
      title: "High Agency Podcast",
      url: "/podcast",
    },
  ],
  Company: [
    {
      title: "Careers",
      display: (
        <span className="flex items-center gap-8">
          Careers <Badge>{NUMBER_OF_JOB_OPENINGS}</Badge>
        </span>
      ),
      url: CAREERS_LINK,
    },
    {
      title: "About",
      url: ABOUT_PAGE_LINK,
    },
    {
      title: "Trust Center",
      url: VANTA_TRUST_REPORT_LINK,
    },
  ],
  // Product: [],
  Social: [
    {
      title: "𝕏",
      url: TWITTER_LINK,
      target: "_blank",
    },
    {
      title: "GitHub",
      url: GITHUB_LINK,
      target: "_blank",
    },
    {
      title: "LinkedIn",
      url: LINKEDIN_LINK,
      target: "_blank",
    },
  ],
};

export const Footer = ({
  showLatestNews = true,
}: {
  showLatestNews?: boolean;
}) => {
  return (
    <div className="container-lg py-48 md:py-60">
      {showLatestNews && (
        <>
          <div className="grow">
            <HumanloopLogo className="h-32 shrink-0" />
          </div>
          <div className="border-b border-gray-100 py-48">
            <FooterLatestNews />
          </div>
        </>
      )}
      <div className="flex flex-col items-start justify-between gap-y-32 py-60 md:flex-row">
        <div className="grid  w-full shrink grid-cols-2 gap-y-32 md:grid-cols-3">
          {Object.entries(FooterLinks).map(([section, links]) => (
            <div
              key={section}
              className="flex  flex-col justify-start gap-16 text-14-20"
            >
              <span className="pb-8 text-14-14 text-gray-600">{section}</span>
              {links.map(({ title, url, display, target }) => (
                <Link
                  key={title}
                  href={url}
                  className="text-14-20 text-gray-900 flex gap-6 hover:underline items-center"
                  target={target}
                >
                  {display || title}
                  {target === "_blank" && (
                    <ArrowUpRightIcon className="h-12 w-12 text-neutral-300" />
                  )}
                </Link>
              ))}
            </div>
          ))}
        </div>
        <div className="mt-40 flex w-full justify-end md:-mt-0 md:w-fit">
          <div className="flex w-full flex-col gap-16 md:min-w-[272px] md:gap-24">
            <div className="text-14-14 text-gray-600">Newsletter</div>
            <NewsletterForm />
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex justify-between gap-12 border-t border-gray-100 py-24 text-12-12 text-gray-600 sm:py-36 sm:text-14-16">
          <div>© 2020 - 2045 Humanloop, Inc.</div>
          <div className="flex gap-32">
            <a href={"/policies"} className="hover:text-gray-950">
              Terms & Policies
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
