import Badge, { BadgeShade } from "@components/library/Badge";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Button, { ButtonProps } from "components/library/Button";
import IconButton from "components/library/IconButton";
import { ArrowUpRightIcon } from "components/library/Icons";
import { HumanloopLogo } from "components/library/Logos";
import { classNames } from "components/library/utils/classNames";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import {
  EventPurpose,
  EventSection,
  analytics,
} from "services/analytics.service";
import {
  CAREERS_LINK,
  DEMO_FORM_LINK,
  HOMEPAGE_LINK,
  HUMANLOOP_APP_LOGIN,
  HUMANLOOP_APP_SIGNUP,
  DOCS_LINK,
  NUMBER_OF_JOB_OPENINGS,
} from "utils/constants";

interface BaseHeaderItem {
  name: string;
  classNames?: string;
}
interface NestedLink extends BaseHeaderItem {
  items: WebsiteLink[]; // Allowing only one level of nesting
}
interface HeaderLink extends BaseHeaderItem {
  href: string;
  external?: boolean;
  badge?: string;
  display?: React.ReactNode; // optional way to display the link
}

type HeaderItem = NestedLink | HeaderLink;

const isNestedLink = (item: HeaderItem): item is NestedLink => {
  return "items" in item;
};

interface WebsiteLink {
  name: string;
  href: string;
  Icon?:
    | React.FunctionComponent<React.ComponentProps<"svg">>
    | React.FunctionComponent<React.ComponentProps<"img">>;
  description?: string;
}

const headerLinks: HeaderItem[] = [
  { name: "Pricing", href: "/pricing" },
  { name: "Customers", href: "/customers" },
  // { name: "Team", href: "/about", classNames: "hidden lg:block" },
  { name: "Blog", href: "/blog" },
  { name: "Docs", href: DOCS_LINK },
  // {
  //   name: "Careers",
  //   href: CAREERS_LINK,
  //   external: true,
  //   classNames: "hidden lg:block",
  //   display: (
  //     <span className="flex items-center gap-x-8">
  //       Careers
  //       <Badge
  //         shade={BadgeShade.Blue}
  //         className="hidden !p-0 text-11-14 md:block"
  //       >
  //         {NUMBER_OF_JOB_OPENINGS}
  //       </Badge>
  //     </span>
  //   ),
  // },
];

export const Header = ({ xlWidth = true }: { xlWidth?: boolean }) => {
  const router = useRouter();

  const buttonClasses = "!text-14-20 ";

  return (
    <Popover className="">
      <div
        className={classNames(
          "mx-auto flex w-full items-center justify-between py-20 md:py-40",
          xlWidth ? "container-xl" : "container-lg ",
        )}
      >
        <Button href={HOMEPAGE_LINK} styling="ghost" className="-ml-10">
          <HumanloopLogo className="-mb-4 h-28 text-gray-950 " />
        </Button>

        <div className="flex items-center gap-6 ">
          <div className="hidden items-center gap-6 mr-2 md:flex">
            {headerLinks.map((item) =>
              isNestedLink(item) ? (
                <HeaderDropdown
                  key={item.name}
                  name={item.name}
                  items={item.items}
                />
              ) : (
                <HeaderButton
                  key={item.name}
                  item={item}
                  buttonClasses={classNames(buttonClasses, item.classNames)}
                />
              ),
            )}
          </div>
          <Button
            href={HUMANLOOP_APP_LOGIN}
            size={32}
            className={classNames(
              buttonClasses,
              "shadow-button",
              "hidden hover:border-gray-200 whitespace-nowrap hover:bg-gray-100 md:flex",
            )}
            onClick={() =>
              analytics.track("Button Clicked", {
                section: EventSection.Header,
                purpose: EventPurpose.Login,
                url: HUMANLOOP_APP_LOGIN,
              })
            }
            styling="outline"
            shade="gray"
          >
            Sign in
          </Button>

          <Button
            href={DEMO_FORM_LINK}
            styling={"bold"}
            size={32}
            onClick={() =>
              analytics.track("Button Clicked", {
                section: EventSection.Header,
                purpose: EventPurpose.Sales,
                url: DEMO_FORM_LINK,
              })
            }
            className={classNames(
              "flex-none",
              buttonClasses,
              "shadow-button",
              "hidden hover:border-blue-700 hover:bg-blue-700 md:flex",
            )}
          >
            Book a demo
          </Button>
          <Popover.Button
            className="-my-8 md:hidden" // neg margin to not expand button height next to this.
            as={IconButton}
            // shade={"blue"}
            Icon={MenuIcon}
            size={40}
          />
        </div>
      </div>
      {/* Mobile panel */}
      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute inset-x-0 bottom-0 top-0 z-[60] origin-top-right transform transition md:hidden"
        >
          {({ close }) => <MobileMenu links={headerLinks} close={close} />}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const HeaderDropdown = ({
  name,
  items,
}: {
  name: string;
  items: WebsiteLink[];
}) => {
  return (
    <Popover.Group as="nav" className="hidden md:flex">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              as={Button}
              styling="ghost"
              square
              active={open}
              IconRight={ChevronDownIcon}
            >
              {name}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 -ml-16 mt-12 w-screen max-w-md transform px-4 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                <div className="overflow-hidden rounded-lg border border-gray-400 bg-white shadow-b">
                  <div className="relative grid gap-12 bg-white px-8 py-12">
                    {items.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex  items-start gap-12 rounded px-12 py-8  hover:bg-gray-200"
                      >
                        {item.Icon && (
                          <item.Icon
                            className="h-32 w-32 flex-shrink-0"
                            aria-hidden="true"
                          />
                        )}
                        <div className="ml-4 flex flex-col gap-4">
                          <p className="text-14-16 text-gray-950">
                            {item.name}
                          </p>
                          <p className=" text-14-16 text-gray-700">
                            {item.description}
                          </p>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Popover.Group>
  );
};

const MobileMenu = ({
  links,
  close,
}: {
  links: HeaderItem[];
  close: () => void;
}) => {
  const [currentLinks, setCurrentLinks] = useState<HeaderItem[]>(links);

  return (
    <div className="flex h-full flex-col justify-between gap-20 bg-gradient-to-b from-gray-950 via-gray-950 to-blue-950 ">
      <div>
        <div className="flex items-center justify-between p-12 ">
          <div className="px-12 pt-12">
            <HumanloopLogo className="h-[26px] text-white" />
          </div>
          <div>
            <Button
              IconLeft={ArrowLeftIcon}
              size={40}
              square
              styling="ghost"
              className={classNames(
                "text-white hover:border-blue hover:text-white",
                JSON.stringify(currentLinks) === JSON.stringify(links)
                  ? "hidden"
                  : "",
              )}
              onClick={() => setCurrentLinks(links)}
            >
              Back
            </Button>
          </div>
          <Popover.Button
            as={IconButton}
            Icon={XIcon}
            size={40}
            square
            className="rounded text-white hover:border-blue hover:text-blue-300"
          />
        </div>
        <div className="mt-64 px-12">
          <nav className="grid gap-y-32">
            {currentLinks.map((link) =>
              isNestedLink(link) ? (
                <button
                  key={link.name}
                  onClick={() => {
                    setCurrentLinks(link?.items);
                  }}
                  className="text-display flex items-center gap-8 border border-transparent px-12 py-6 text-left text-[28px]  leading-[100%] tracking-wide text-white transition-all hover:gap-24 hover:border-white hover:text-white"
                >
                  {link.name}{" "}
                  <ArrowRightIcon className="h-24 w-24 text-gray-400" />
                </button>
              ) : (
                <Link
                  key={link.name}
                  href={link.href as string}
                  target={link.external ? "_blank" : undefined}
                  onClick={() => {
                    analytics.track("Button Clicked", {
                      section: EventSection.Header,
                      url: link.href,
                    });
                    close();
                  }}
                  className="text-display flex items-center gap-8 border border-transparent px-12 py-6 text-left text-[28px]  leading-[100%] tracking-wide text-white hover:border-white hover:text-white"
                >
                  {link.name}
                  {link.external && (
                    <ArrowUpRightIcon className="h-24 w-24 text-gray-400" />
                  )}
                </Link>
              ),
            )}
          </nav>
        </div>
      </div>
      <div className="mx-24 mb-32 flex flex-col gap-12">
        <Button
          size={52}
          styling="outline"
          href={HUMANLOOP_APP_LOGIN}
          className="w-full !border-0 bg-transparent text-13-14 font-bold text-white hover:bg-white hover:text-gray-950"
          onClick={() =>
            analytics.track("Button Clicked", {
              section: EventSection.Header,
              purpose: EventPurpose.Login,
              url: HUMANLOOP_APP_LOGIN,
            })
          }
        >
          Sign in
        </Button>
        <Button
          size={52}
          styling="outline"
          href={HUMANLOOP_APP_SIGNUP}
          className=" shadow-button w-full border-0 bg-white text-13-14 font-bold text-black hover:bg-blue-900 hover:text-white"
          onClick={() =>
            analytics.track("Button Clicked", {
              section: EventSection.Header,
              purpose: EventPurpose.Signup,
              url: HUMANLOOP_APP_SIGNUP,
            })
          }
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

/** Component to render size-32 button at lg and above, and size-24 button below */
const HeaderButton = ({
  item,
  buttonClasses,
}: {
  item: HeaderLink;
  buttonClasses: string;
}) => {
  const router = useRouter();
  const buttonProps: ButtonProps = {
    href: item.href,
    className: classNames(
      "group flex-none",
      buttonClasses,
      "rounded !py-[7px] hover:border-gray-200 hover:shadow-button-1",
    ),
    styling: "ghost",
    active: router.pathname.startsWith(item.href),
    square: true,
    target: item.external ? "_blank" : undefined,
    onClick: () =>
      analytics.track("Button Clicked", {
        section: EventSection.Header,
        url: item.href,
      }),
    children: (
      <>
        {item.display ? item.display : item.name}
        {item.badge && <Badge shade={BadgeShade.Blue}>{item.badge}</Badge>}
      </>
    ),
    IconRight: item.external
      ? (props: any) => (
          <div className="invisible relative right-2 top-2 transition-all group-hover:visible group-hover:right-0 group-hover:top-0 group-hover:opacity-100">
            <ArrowUpRightIcon {...props} />
          </div>
        )
      : undefined,
  };
  return (
    <>
      <Button
        size={32}
        {...buttonProps}
        className={classNames("hidden lg:flex", buttonProps.className)}
      />
      <Button
        size={24}
        {...buttonProps}
        className={classNames("lg:hidden", buttonProps.className)}
      />
    </>
  );
};
